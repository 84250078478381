.display--flex{
    display:flex;
    justify-content: center;
    align-items: center;
}

.form_container{
    margin: 20px;
}

.font{
    font-family: 'Gotham';
}